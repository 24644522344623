<template>
  <v-ons-page>
    <v-ons-toolbar style="height: 44px">
      <div class="left">
        <v-ons-back-button>戻る</v-ons-back-button>
      </div>
      <div class="center">
        <img src="img/settings/header_logo.png" height="44" />
      </div>
    </v-ons-toolbar>
    <div>
      <v-ons-list>
        <v-ons-list-item>
          <div style="padding: 20px; width: 100%">

            <template v-if="$store.state.loginUser.contractId == 1 || $store.state.loginUser.contractId == 2 || $store.state.loginUser.contractId == 3 || $store.state.loginUser.contractId == 4 || $store.state.loginUser.contractId == 5 || $store.state.loginUser.contractId == 8 || $store.state.loginUser.contractId == 9">
              <div class="contract_title" style="margin: 0px 0px 20px 0px;">教材のダウンロード（講義、えんぷり）</div>
            </template>

            <template v-if="$store.state.loginUser.contractId == 1">
              <div v-for="name in aList.slice(0, 1)" v-bind:key="'e.' + name">
                <div style="margin: 10px;">
                  <div>{{ name }}</div>
                  <div style="font-size: 11px;">
                    <span><a :href="'pdf/' + aPath + '/' + name.slice(0, 2) + '_kougi.pdf'" :download="name + '_講義ノート.pdf'">講義ノート</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + aPath + '/' + name.slice(0, 2) + '_enpuri.pdf'" :download="name + '_えんぷり問題.pdf'">えんぷり問題</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + aPath + '/' + name.slice(0, 2) + '_enpuku.pdf'" :download="name + '_えんぷく問題.pdf'">えんぷく問題</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + aPath + '/' + name.slice(0, 2) + '_pukupuku.pdf'" :download="name + '_ぷくぷく問題.pdf'">ぷくぷく問題</a></span>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="$store.state.loginUser.contractId == 2 || $store.state.loginUser.contractId == 3 || $store.state.loginUser.contractId == 4 || $store.state.loginUser.contractId == 5 || $store.state.loginUser.contractId == 8 || $store.state.loginUser.contractId == 9">
              <div v-for="name in aList" v-bind:key="'e.' + name">
                <div style="margin: 10px;">
                  <div>{{ name }}</div>
                  <div style="font-size: 11px;">
                    <span><a :href="'pdf/' + aPath + '/' + name.slice(0, 2) + '_kougi.pdf'" :download="name + '_講義ノート.pdf'">講義ノート</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + aPath + '/' + name.slice(0, 2) + '_enpuri.pdf'" :download="name + '_えんぷり問題.pdf'">えんぷり問題</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + aPath + '/' + name.slice(0, 2) + '_enpuku.pdf'" :download="name + '_えんぷく問題.pdf'">えんぷく問題</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + aPath + '/' + name.slice(0, 2) + '_pukupuku.pdf'" :download="name + '_ぷくぷく問題.pdf'">ぷくぷく問題</a></span>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="$store.state.loginUser.contractId == 3 || $store.state.loginUser.contractId == 4 || $store.state.loginUser.contractId == 5 || $store.state.loginUser.contractId == 8 || $store.state.loginUser.contractId == 9">
              <div v-for="name in bList" v-bind:key="'e.' + name">
                <div style="margin: 10px;">
                  <div>{{ name }}</div>
                  <div style="font-size: 11px;">
                    <span><a :href="'pdf/' + bPath + '/' + name.slice(0, 2) + '_kougi.pdf'" :download="name + '_講義ノート.pdf'">講義ノート</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + bPath + '/' + name.slice(0, 2) + '_enpuri.pdf'" :download="name + '_えんぷり問題.pdf'">えんぷり問題</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + bPath + '/' + name.slice(0, 2) + '_enpuku.pdf'" :download="name + '_えんぷく問題.pdf'">えんぷく問題</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + bPath + '/' + name.slice(0, 2) + '_pukupuku.pdf'" :download="name + '_ぷくぷく問題.pdf'">ぷくぷく問題</a></span>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="$store.state.loginUser.contractId == 4 || $store.state.loginUser.contractId == 5 || $store.state.loginUser.contractId == 8 || $store.state.loginUser.contractId == 9">
              <div v-for="name in cList" v-bind:key="'e.' + name">
                <div style="margin: 10px;">
                  <div>{{ name }}</div>
                  <div style="font-size: 11px;">
                    <span><a :href="'pdf/' + cPath + '/' + name.slice(0, 2) + '_kougi.pdf'" :download="name + '_講義ノート.pdf'">講義ノート</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + cPath + '/' + name.slice(0, 2) + '_enpuri.pdf'" :download="name + '_えんぷり問題.pdf'">えんぷり問題</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + cPath + '/' + name.slice(0, 2) + '_enpuku.pdf'" :download="name + '_えんぷく問題.pdf'">えんぷく問題</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + cPath + '/' + name.slice(0, 2) + '_pukupuku.pdf'" :download="name + '_ぷくぷく問題.pdf'">ぷくぷく問題</a></span>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="$store.state.loginUser.contractId == 5 || $store.state.loginUser.contractId == 9">
              <div class="contract_title" style="margin: 20px 0px 20px 0px;">教材のダウンロード（はいぷり）</div>
              <div v-for="name in hList" v-bind:key="'h.' + name">
                <div style="margin: 10px;">
                  <div>{{ name }}</div>
                  <div style="font-size: 11px;">
                    <span><a :href="'pdf/' + hPath + '/' + name.slice(0, 2) + '_highpuri.pdf'" :download="name + '_はいぷり問題.pdf'">はいぷり問題</a></span>
                    <span style="margin-left: 10px;"><a :href="'pdf/' + kPath + '/' + name.slice(0, 2) + '_commentary.pdf'" :download="name + '_はいぷり解説書き込み用.pdf'">はいぷり解説書き込み用</a></span>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="$store.state.loginUser.contractId == 5 || $store.state.loginUser.contractId == 9">
              <div class="contract_title" style="margin: 20px 0px 20px 0px;">教材のダウンロード（なんぷり）</div>
              <div>
                <div style="margin: 10px;">
                  <div style="font-size: 11px;">
                    <span><a href="pdf/nea87127-7982-3aa1-4y67-32ff3b93d8ax/nanpuri.pdf" download="なんぷり問題.pdf">なんぷり問題</a></span>
                    <span style="margin-left: 10px;"><a href="pdf/nea87127-7982-3aa1-4y67-32ff3b93d8ax/nankai.pdf" download="なんぷり解説.pdf">なんぷり解説</a></span>
                  </div>
                </div>
              </div>
            </template>            

          </div>
        </v-ons-list-item>
      </v-ons-list>
    </div>
  </v-ons-page>
</template>

<style scoped>
.list-item {
  height: initial;
}
</style>

<script>

const LIST_1A = [
  '01_数と式・集合と論理',
  '02_2次関数',
  '03_図形と計量',
  '04_データの分析',
  '05_場合の数と確率',
  '06_図形の性質',
  '07_数学と人間の活動',
];

const LIST_2B = [
  '08_式と証明',
  '09_複素数と方程式',
  '10_図形と方程式',
  '11_三角関数',
  '12_指数・対数関数',
  '13_微分積分',
  '14_数列',
  '15_確率分布と統計的な推測',
  '16_ベクトル',
];

const LIST_3X = [
  '17_平面上の曲線と複素数平面',
  '18_極限',
  '19_微分',
  '20_積分',
];

const LIST_HP = [
  '01_数と式・集合と論理',
  '89_いろいろな式',
  '14_数列',
  '36_Ⅰ・Ａ図形総合',
  '10_図形と方程式',
  '16_ベクトル',
  '17_平面上の曲線と複素数平面',
  '02_2次関数',
  '11_三角関数',
  '12_指数対数関数',
  '13_数Ⅱ微積',
  '05_場合の数と確率',
  '04_データの分析',
  '15_確率分布と統計的な推測',
  '07_整数問題',
  '18_極限',
  '19_微分',
  '20_積分',
];

export default {
  data() {
    return {
      aPath: 'afafdfba-7cc4-d92d-e4bd-7820ca25cd81',
      bPath: 'b23d2e9e-650a-f730-12d0-5948493a9528',
      cPath: 'cd9e48ee-a8d6-bf16-54ad-7d7b13b9d734',
      hPath: 'h01cb6bc-663f-9177-f95b-61edb3def154',
      kPath: 'kea0c123-74a2-33d1-4367-79d3b3f3dea0',
      aList: LIST_1A,
      bList: LIST_2B,
      cList: LIST_3X,
      hList: LIST_HP
    };
  },
  methods: {
    popTopPage() {
      this.$emit("top-page");
    },
  },
};
</script>
